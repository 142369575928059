import HomeTitle from '../HomeTitle-mobile';
import style from './index.module.scss';
import daisy from '../../../../images/daisy.png';
import edwin from '../../../../images/edwin.png';
import emma from '../../../../images/emma.png';
import tianhao from '../../../../images/tianhao.png';
import yuanshuai from '../../../../images/yuanshuai.png';
import gaoyi from '../../../../images/gaoyi.png';
import jobs from '../../../../images/jobs.png';
import tfz from '../../../../images/tfz.png';
import penglai from '../../../../images/penglai.png';
import stella from '../../../../images/stella.png';
import mengcheng from '../../../../images/mengcheng.png';
import ligong from '../../../../images/ligong.png';
import kriswu from '../../../../images/kriswu.png';
import emmawang from '../../../../images/emmawang.png';

import { useRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const cardArray = [
    {
        topIcon: daisy,
        subItems: [
            '新加坡国立大学本科',
            '丰富的金融行业从业经验，曾先后就职于JP Morgan、Merrill Lynch 等知名美资银行。',
            '金融科技、跨境电商领域连续创业者',
        ],
        name: 'Daisy 艾悦',
        title: '创始合伙人',
        englishsubItems: [
            "National University of Singapore (NUS) Bachelor's degree",
            'Ex-JP Morgan, Ex- Merrill Lynch, Serial entrepreneur in the fields of financial technology and cross-border e-commerce',
        ],
        englishname: 'Daisy (Ai Yue)',
        englishtitle: 'Founding Partner',
    },
    {
        topIcon: tfz,
        subItems: [
            '北大数学&经管双学位，普渡大学经济学硕士，哥伦比亚大学金融学博士',
            '多资产配置专家，曾任AQR（全球第二大对冲基金）副总裁',
            '多资产策略专家，曾任State Street（全球最大托管行）策略分析师',
        ],
        name: 'Ark 史方舟',
        title: '首席投资专家顾问',
        englishsubItems: [
            "Peking University Mathematics & Economics and Management Double Degree, Purdue University Master's degree of Economics, Columbia University PhD in Finance",
            'Multi-asset allocation specialist, Ex-AQR (2nd largest hedge fund in the world) VP',
            "Multi-asset strategy specialist, Ex- State Street (the world's largest custody bank) Strategy Analyst",
        ],
        englishname: 'Ark (Shi Fangzhou)',
        englishtitle: 'Principal Investment Advisor',
    },
    {
        topIcon: edwin,
        subItems: [
            '前美团设计负责人',
            '曾就职于腾讯等头部互联网科技公司',
            '服务过多个亿级产品体验设计，拥有电商、社交、教育、游戏等多个领域品牌及体验设计经验',
        ],
        name: 'Edwin 薛靖',
        title: '首席体验官',
        englishsubItems: [
            'Ex-Meituan Experience Design Director',
            'Ex-17 Education VP',
            'Worked for Internet companies like Tencent, Fetion and NetDragon etc.',
        ],
        englishname: 'Edwin (Xue Jing)',
        englishtitle: 'CXO',
    },
    {
        name: 'Emma Wang',
        topIcon: emmawang,
        title: '合伙人',
        subItems: [
            '南京大学本科、美国宾夕法尼亚大学沃顿商学院工商管理硕士金融专业',
            '全球化战略、一级项目及基金投资专家，曾在字节跳动战略与投资部负责国际化战略',
            '公司金融、资本市场和并购专家，曾任高盛亚洲投行部执行董事',
        ],
    },
    {
        topIcon: jobs,
        subItems: ['同济大学本科', '前阅文集团业务负责人', '前诺亚财富互联网部门总监'],
        name: 'Jobs 蒋彪',
        title: '合伙人',
        englishsubItems: [
            '10+ years experience of work + entrepreneurship in Internet industry',
            'Ex-Yue Wen Business Owner, Noah Internet director, Tencent product manager',
        ],
        englishname: 'Jobs (Jiang Bo)',
        englishtitle: 'Partner',
    },

    {
        topIcon: emma,
        subItems: [
            '中央财经大学计算机学士，金融硕士',
            '曾任职于中非信银基金海外投资组，负责澳大利亚、东南亚（新加坡、马来西亚、印尼等）私募股权投资',
        ],
        name: 'Emma 于洁',
        title: '投资顾问',
        englishsubItems: [
            "Central University of Finance and Economics Bachelor's degree in Computing Science, Master's degree in Finance",
            'Ex-China-Africa CNCB Fund overseas investment group, in charge of private equity investment in areas of Australia, South East Asia (Singapore, Malaysia and Indonesia etc.)',
        ],
        englishname: 'Emma (Yu Jie)',
        englishtitle: 'Investment Counsellor',
    },

    {
        name: 'Stella',
        title: '总监 / IC负责人',
        topIcon: stella,
        subItems: [
            '特许金融分析师CFA',
            '新加坡国立大学荣誉双学士，硕士',
            '曾任职于摩根大通投资银行全球市场部，摩根大通私人银行部',
        ],
    },
    {
        name: '力弓',
        title: '总监',
        topIcon: ligong,
        subItems: [
            '特许金融分析师CFA, 特许另类投资分析师CAIA',
            '南洋理工大学本科-金融与会计双学位',
            '曾任职于瑞士银行UBS、瑞士信贷Credit Suisse、摩根大通银行JP Morgan等知名国际金融机构',
            '从业多年，有丰富的投资经验，有独到观点',
        ],
    },
    {
        name: 'Roc 任鹏来',
        title: '总监',
        topIcon: penglai,
        subItems: [
            '英国华威大学&香港理工大学硕士',
            '曾任职于头部互联网公司字节跳动',
            '曾任职于国内头部母基金',
        ],
    },
    {
        name: 'Alan 曾梦成',
        title: '资深分析师',
        topIcon: mengcheng,
        subItems: [
            '香港中文大学，金融学学士',
            '前头部咨询公司投研分析师，覆盖TMT、新能源及消费等行业',
        ],
    },
    {
        name: 'Kris 吴仲龙',
        title: '资深分析师',
        topIcon: kriswu,
        subItems: [
            '伦敦商学院金融分析硕士，香港大学会计财务本科',
            '曾任职于头部美元对冲基金',
            '对中美互联网、半导体、消费有深入研究经验',
        ],
    },
    {
        topIcon: yuanshuai,
        subItems: ['香港城市大学硕士', '曾任职于金牛私募基金，连续4年获得金牛奖私募'],
        name: 'Danny 袁帅',
        title: '宏观分析师',
        englishsubItems: [
            "City University of Hong Kong Master's degree",
            'Ex-Taurus Private Equity Fund, 4-year winner for the Golden Bull Award for Private Equity',
        ],
        englishname: 'Yuan shuai',
        englishtitle: 'Macro Analyst',
    },
    {
        topIcon: tianhao,
        subItems: [
            '新加坡南洋理工大学硕士',
            '曾任职于头部加密货币矿池/美元对冲基金',
            '对公链，DeFi蓝筹项目，Web 3.0有深入研究经验',
        ],
        name: 'Torres 孙田浩',
        title: '数字资产分析师',
        englishsubItems: [
            "Nanyang Technological University Master's degree",
            'Worked in top cryptocurrency mining pools/USD Hedge Funds',
            'In-depth research experience on public chains, DeFi blue-chip projects, and Web 3.0',
        ],
        englishname: 'Sun Tianhao',
        englishtitle: 'Digital Asset Analyst',
    },
    {
        topIcon: gaoyi,
        subItems: ['厦门大学硕士', '曾任职于知名量化私募基金'],
        name: '高毅',
        title: '量化分析师',
        englishsubItems: [
            "Xiamen University Master's Degree",
            'Worked in well-known quantitative private equity funds',
        ],
        englishname: 'Gao Yi',
        englishtitle: 'Quantitative Analyst',
    },
];
const CustomLi = ({ title }) => {
    return (
        <div className={style.customli}>
            <div className={style.circle}></div>
            <div className={style.text}>{title}</div>
        </div>
    );
};

const TeamLeader = () => {
    const statusLineRef = useRef(null);
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const on = function (element, event, handler) {
            if (element && event && handler) {
                element.addEventListener(event, handler, false);
            }
        };

        const off = function (element, event, handler) {
            if (element && event) {
                element.removeEventListener(event, handler, false);
            }
        };

        let targetDrag = {
            isDown: false,
            coord: {
                x: 0,
                y: 0,
            },
        };

        const scrollMousedown = event => {
            targetDrag.isDown = true;
            targetDrag.coord.x = event.pageX;
            targetDrag.coord.y = event.pageY;
        };
        const scrollMouseup = () => {
            targetDrag.isDown = false;
            targetDrag.coord.x = 0;
            targetDrag.coord.y = 0;
        };

        let el = statusLineRef.current;

        const scrollMousemove = event => {
            let movX = targetDrag.coord.x - event.pageX;
            targetDrag.coord.x = event.pageX;
            if (targetDrag.isDown) {
                el.scrollLeft = el.scrollLeft + movX;
            }
        };

        if (el) {
            on(el, 'mousedown', scrollMousedown);
            on(document, 'mouseup', scrollMouseup);
            on(el, 'mousemove', scrollMousemove);
        }
        return () => {
            if (el) {
                off(el, 'mousedown', scrollMousedown);
                off(document, 'mouseup', scrollMouseup);
                off(el, 'mousemove', scrollMousemove);
            }
        };
    }, [statusLineRef]);
    const [scrollLeft, setScrollLeft] = useState(0);

    useEffect(() => {
        let contentwidth = window.innerWidth;
        let totalwidth = statusLineRef.current.scrollWidth;
        let fn = () => {
            setScrollLeft(
                (statusLineRef.current.scrollLeft / (totalwidth - contentwidth)) * (17000 / 240.0),
            );
        };
        if (statusLineRef.current) {
            statusLineRef.current.addEventListener('scroll', fn);
        }
        return () => {
            if (statusLineRef.current) statusLineRef.current.removeEventListener('scroll', fn);
        };
    }, [statusLineRef]);
    return (
        <div className={`${style.body} ${i18n.language === 'en' ? style.bodyEn : ''}`}>
            <div className="nav-anchor" id="team"></div>
            <HomeTitle title={t('team-title')} titleColor="white"></HomeTitle>
            <div className={style.slider}>
                <div className={style.sliderContent} style={{ left: `${scrollLeft}%` }}></div>
            </div>
            <div className={style.title}>{t('team-desc')}</div>
            <div className={`${style.scroll} ${style['hide-scrollbar']}`} ref={statusLineRef}>
                {[...Array(8).keys()].map((item, index) => {
                    let topDataItem = cardArray[item * 2];
                    let bottomDataItem = cardArray[item * 2 + 1];
                    return (
                        <div className={style.card} key={index} style={{ alignSelf: 'flex-start' }}>
                            {topDataItem && (
                                <div className={style.cardItem}>
                                    <img src={topDataItem.topIcon} alt="" className={style.img} />
                                    <div className={style.CustomLiContent}>
                                        <div className={style.top}>
                                            <div className={style.name}>
                                                {i18n.language === 'zh'
                                                    ? topDataItem.name
                                                    : topDataItem.englishname}
                                            </div>
                                            <div className={style.bottomtitle}>
                                                {i18n.language === 'zh'
                                                    ? topDataItem.title
                                                    : topDataItem.englishtitle}
                                            </div>
                                        </div>
                                        {(i18n.language === 'zh'
                                            ? topDataItem.subItems
                                            : topDataItem.englishsubItems
                                        ).map(item => {
                                            return <CustomLi title={item} key={item}></CustomLi>;
                                        })}
                                    </div>
                                </div>
                            )}

                            {bottomDataItem && (
                                <div className={style.cardItem}>
                                    <img
                                        src={bottomDataItem.topIcon}
                                        alt=""
                                        className={style.img}
                                    />
                                    <div className={style.CustomLiContent}>
                                        <div className={style.top}>
                                            <div className={style.name}>
                                                {i18n.language === 'zh'
                                                    ? bottomDataItem.name
                                                    : bottomDataItem.englishname}
                                            </div>
                                            <div className={style.bottomtitle}>
                                                {i18n.language === 'zh'
                                                    ? bottomDataItem.title
                                                    : bottomDataItem.englishtitle}
                                            </div>
                                        </div>
                                        {(i18n.language === 'zh'
                                            ? bottomDataItem.subItems
                                            : bottomDataItem.englishsubItems
                                        ).map(item => {
                                            return <CustomLi title={item} key={item}></CustomLi>;
                                        })}
                                    </div>
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};
export default TeamLeader;
