import style from './index.module.scss';
import { useState } from 'react';
import HomeTitle from '../HomeTitle-mobile';
import bgImg from '../../../../images/bank-bg-mobile.png';
import { useTranslation } from 'react-i18next';
import { urlArray } from '../bank';
const BrandLogo = props => {
    const { value } = props;

    let image = require(`../../../../images/cooperation_bank_0${value + 1}.png`);
    const [hovered, setHovered] = useState(false);

    return (
        <div
            style={{
                height: '100%',
                width: '100%',
                // backgroundColor: 'white',
            }}
            key={value}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <a
                style={{ height: '100%', width: '100%', display: 'block', borderRadius: '16px' }}
                onClick={() => {
                    window.open(urlArray[value]);
                }}
            >
                <img
                    src={image.default}
                    // style={{
                    //     opacity: hovered ? 0 : 1,
                    // }}
                    className={`mobile-bankDefaultImg`}
                />
                {/* <div
                    style={{
                        position: 'absolute',
                        height: '100%',
                        opacity: hovered ? 1 : 0,
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                        padding: '0 20px',
                        width: '100%',
                        left: 0,
                        borderRadius: '16px',
                        backgroundColor: '#FFD400',
                        transition: 'opacity 0.2s ease-in-out',
                    }}
                    onClick={() => {
                        window.open(urlArray[value]);
                    }}
                >
                    <div className="bank-hover-name">{bankName[value]}</div>
                    <div className="bank-hover-body">{bankDesc[value]}</div>
                </div> */}
            </a>
        </div>
    );
};

const Bank = () => {
    const { t, i18n } = useTranslation();

    return (
        <div
            className={`${style.container} ${i18n.language === 'en' ? style.enContainer : ''}`}
            id="partners"
        >
            <img src={bgImg} alt="" className={style.bgimg} />
            <div className={style.contentTop}>
                <HomeTitle title={t('bank-mobile-title')}></HomeTitle>
                <div className={style.content}>
                    {[...Array(10).keys()].map(value => (
                        <div
                            className={style.bank}
                            key={value}
                            style={{
                                display: value === 4 ? 'none' : undefined,
                            }}
                        >
                            <BrandLogo value={value} key={value} />
                        </div>
                    ))}
                </div>
                <div className={i18n.language !== 'en' ? style.desc : style.enDesc}>
                    {t('bank-desc')}
                </div>
            </div>
        </div>
    );
};
export default Bank;
